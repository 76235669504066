
















































import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class UserInfo extends Vue {
  public member = {};

  // 获取用户信息
  getMember() {
    let _this = this;
    this.$api.request({
      url: "user/member/info",
      success(res) {
        _this.member = res.data;
      }
    });
  }

  init() {
    this.member = {};
    this.getMember();
  }
}
